<template>
  <div>
    <el-form v-model="form">
        <el-form-item>
            <div class="switch-wrap-label">基础设置</div>
            <div class="switch-wrap">
                <el-row>
                    <el-col :span="11">
                        <el-form-item>
                            <el-switch v-model="form.browserApi.setBatteryStatus" active-text="设置Battery Status API"></el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" :offset="2">
                        <el-form-item>
                            <el-switch :disabled="!form.browserApi.setBatteryStatus" v-model="form.browserApi.isCharging" active-text="是否正在充电"></el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="11">
                        <el-form-item label="充电完成需要时间:">
                            <el-select v-model="form.browserApi.chargingTime" :disabled="!form.browserApi.setBatteryStatus" style="width:100%">
                                <el-option label="0" value="0"></el-option>
                                <el-option label="Infinity" value="Infinity"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" :offset="2">
                        <el-form-item label="电量可以使用时间:">
                            <el-select v-model="form.browserApi.drainsTime" :disabled="!form.browserApi.setBatteryStatus" style="width:100%">
                                <el-option label="3600" value="3600"></el-option>
                                <el-option label="18000" value="18000"></el-option>
                                <el-option label="10800" value="10800"></el-option>
                                <el-option label="12600" value="12600"></el-option>
                                <el-option label="Infinity" value="Infinity"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item>
                    <div style="font-size: 16px;">电池电量百分比:</div>
                    <el-slider :disabled="!form.browserApi.setBatteryStatus" v-model="batteryPercent" style="padding-left: 20px;"></el-slider>
                </el-form-item>
                <el-row>
                    <el-col :span="11">
                        <el-form-item>
                            <el-switch v-model="form.browserApi.setWebBluetooth" active-text="设置Web Bluetooth API"></el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" :offset="2">
                        <el-form-item>
                            <el-switch v-model="form.browserApi.setBluetoothAdapter" active-text="Bluetooth Adapter"></el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
        </el-form-item>
        <el-form-item>
            <div class="switch-wrap-label">高级指纹保护设置</div>
            <div class="switch-wrap">
                <el-row>
                    <el-col :span="11">
                        <el-form-item>
                            <el-switch v-model="form.maskFonts" active-text="启用字体指纹保护"></el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" :offset="2">
                        <el-form-item>
                            <el-button type="primary" @click="setFont">设置字体</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="11">
                        <el-form-item>
                            <el-switch v-model="form.fontSetting.clientRects" active-text="ClientReacts指纹增强保护"></el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" :offset="2">
                        <el-form-item>
                            <el-switch v-model="form.audio.noise" active-text="启用硬件指纹AudioContext保护（噪声模式）"></el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="11">
                        <el-form-item>
                            <el-switch v-model="form.canvasDefType" :active-value="canvasDefTypeActive" inactive-value="OFF" active-text="canvasDefTypeActive"></el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" :offset="2">
                        <el-form-item>
                            <el-select v-model="form.canvasDefType" :disabled="form.canvasDefType === 'OFF'" style="width:100%">
                                <el-option label="噪声模式A" value="NOISEA"></el-option>
                                <el-option label="封锁模式" value="BLOCK"></el-option>
                                <el-option label="噪声模式B" value="NOISEB"></el-option>
                                <el-option label="噪声模式C" value="NOISEC"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="11">
                        <el-form-item>
                            <el-switch v-model="form.webgl.noise" active-text="启用WebGL图像保护"></el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" :offset="2">
                        <el-form-item>
                            <el-switch v-model="form.webgl.metadata" active-text="启用WebGL元数据保护"></el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row type="flex" align="middle">
                    <el-col :span="11">
                        <el-form-item label="WebGL vendor:">
                            <el-select v-model="form.webgl.vendor" :disabled="!form.webgl.metadata" @change="vendorChange" style="width:100%">
                                <el-option v-for="item in vendor" :key="item.id" :label="item.vendor" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" :offset="2">
                        <el-form-item label="WebGL renderer:">
                            <el-select v-model="form.webgl.renderer" :disabled="!form.webgl.metadata" style="width:100%">
                                <el-option v-for="item in rendererList" :key="item.id" :label="item.renderer" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="2" :offset="1">
                        <el-form-item class="item-no-label" style="margin-bottom:unset">
                            <i class="el-icon-refresh pr-5 shake"></i><span class="shake" @click="vendorChange(form.webgl.vendor)">换一换</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row type="flex" align="middle">
                    <el-col :span="11">
                        <el-form-item>
                            <el-switch v-model="form.timeZoneFillOnStart" active-text="启动基于IP设置时区"></el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" :offset="2">
                        <el-form-item label="手工指定时区:">
                            <el-select v-model="form.timeZone" :disabled="form.timeZoneFillOnStart" style="width:100%">
                                <el-option v-for="item in browserConstDir.customTimeZoneList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
        </el-form-item>
        
        
        <el-form-item>
            <div class="switch-wrap-label">Geolocation</div>
            <div class="switch-wrap">
                <el-form-item>
                    <el-switch v-model="form.browserApi.autoGeoIp" active-text="基于IP地址填充地理位置"></el-switch>
                </el-form-item>
                <el-row>
                    <el-col :span="11">
                        <el-form-item>
                            <el-switch :disabled="form.browserApi.autoGeoIp" v-model="form.browserApi.setLatitude" active-text="维度"></el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" :offset="2">
                        <el-form-item>
                            <el-switch :disabled="form.browserApi.autoGeoIp" v-model="form.browserApi.setLongitude" active-text="经度"></el-switch>
                        </el-form-item>
                        
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="11">
                        <el-form-item>
                            <el-input :disabled="form.browserApi.autoGeoIp" v-model="form.browserApi.latitude"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" :offset="2">
                        <el-form-item>
                            <el-input :disabled="form.browserApi.autoGeoIp" v-model="form.browserApi.longitude"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item>
                    <el-switch :disabled="form.browserApi.autoGeoIp" v-model="form.browserApi.setAccuracy" active-text="精度"></el-switch>
                </el-form-item>
                <el-row>
                    <el-col :span="11">
                        <el-form-item>
                            <el-input :disabled="form.browserApi.autoGeoIp" v-model="form.browserApi.accuracy"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
        </el-form-item>
        
        <el-form-item>
            <div class="switch-wrap-label">SpeechSynthesis API</div>
            <div class="switch-wrap">
                <el-form-item>
                    <el-switch v-model="form.browserApi.speechSynthesis" active-text="自动设置SpeechSynthesls API"></el-switch>
                </el-form-item>
                <el-row>
                    <el-col :span="11">
                        <el-form-item label="speechVoices列表">
                            <el-select v-model="form.browserApi.speechVoicesList" style="width:100%">
                                <el-option v-for="item in voiceList" :key="item.label" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
        </el-form-item>
        
        
        
    </el-form>
    <el-dialog title="设置字体" :visible.sync="dialog" width="50%" :append-to-body="true" class="font-dialog">
        <div class="table-wrap">
            <el-table :data="fontTable" ref="multipleTable" style="width: 100%;" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column label="序号" type="index" width="50"></el-table-column>
                <el-table-column label="字体">
                    <template slot-scope="{row}">
                        <span>{{row}}</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialog = false">取 消</el-button>
            <el-button type="primary" @click="selectedFont">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import browserConstDir from '@/utils/browserConstDir'
import {deepClone} from '@/utils/common'
export default {
    props: ['form','vendor','renderer','openDialog'],
    data(){
        return {
            canvasDefTypeActive: 'NOISEB',
            browserConstDir:browserConstDir,
            dialog: false,
            fontTable: [],
            multipleSelection: [],
            vendorList: [],
            rendererList: [],
            voiceList: [
                {
                    label:'voices_CN',
                    value: 'TWljcm9zb2Z0IEh1aWh1aSAtIENoaW5lc2UgKFNpbXBsaWZpZWQsIFBSQyk=,TWljcm9zb2Z0IEh1aWh1aSAtIENoaW5lc2UgKFNpbXBsaWZpZWQsIFBSQyk=,emgtQ04=,1,1;TWljcm9zb2Z0IEthbmdrYW5nIC0gQ2hpbmVzZSAoU2ltcGxpZmllZCwgUFJDKQ==,TWljcm9zb2Z0IEthbmdrYW5nIC0gQ2hpbmVzZSAoU2ltcGxpZmllZCwgUFJDKQ==,emgtQ04=,1,0;TWljcm9zb2Z0IFlhb3lhbyAtIENoaW5lc2UgKFNpbXBsaWZpZWQsIFBSQyk=,TWljcm9zb2Z0IFlhb3lhbyAtIENoaW5lc2UgKFNpbXBsaWZpZWQsIFBSQyk=,emgtQ04=,1,0;R29vZ2xlIERldXRzY2g=,R29vZ2xlIERldXRzY2g=,ZGUtREU=,0,0;R29vZ2xlIFVTIEVuZ2xpc2g=,R29vZ2xlIFVTIEVuZ2xpc2g=,ZW4tVVM=,0,0;R29vZ2xlIFVLIEVuZ2xpc2ggRmVtYWxl,R29vZ2xlIFVLIEVuZ2xpc2ggRmVtYWxl,ZW4tR0I=,0,0;R29vZ2xlIFVLIEVuZ2xpc2ggTWFsZQ==,R29vZ2xlIFVLIEVuZ2xpc2ggTWFsZQ==,ZW4tR0I=,0,0;R29vZ2xlIGVzcGHDsW9s,R29vZ2xlIGVzcGHDsW9s,ZXMtRVM=,0,0;R29vZ2xlIGVzcGHDsW9sIGRlIEVzdGFkb3MgVW5pZG9z,R29vZ2xlIGVzcGHDsW9sIGRlIEVzdGFkb3MgVW5pZG9z,ZXMtVVM=,0,0;R29vZ2xlIGZyYW7Dp2Fpcw==,R29vZ2xlIGZyYW7Dp2Fpcw==,ZnItRlI=,0,0;R29vZ2xlIOCkueCkv+CkqOCljeCkpuClgA==,R29vZ2xlIOCkueCkv+CkqOCljeCkpuClgA==,aGktSU4=,0,0;R29vZ2xlIEJhaGFzYSBJbmRvbmVzaWE=,R29vZ2xlIEJhaGFzYSBJbmRvbmVzaWE=,aWQtSUQ=,0,0;R29vZ2xlIGl0YWxpYW5v,R29vZ2xlIGl0YWxpYW5v,aXQtSVQ=,0,0;R29vZ2xlIOaXpeacrOiqng==,R29vZ2xlIOaXpeacrOiqng==,amEtSlA=,0,0;R29vZ2xlIO2VnOq1reydmA==,R29vZ2xlIO2VnOq1reydmA==,a28tS1I=,0,0;R29vZ2xlIE5lZGVybGFuZHM=,R29vZ2xlIE5lZGVybGFuZHM=,bmwtTkw=,0,0;R29vZ2xlIHBvbHNraQ==,R29vZ2xlIHBvbHNraQ==,cGwtUEw=,0,0;R29vZ2xlIHBvcnR1Z3XDqnMgZG8gQnJhc2ls,R29vZ2xlIHBvcnR1Z3XDqnMgZG8gQnJhc2ls,cHQtQlI=,0,0;R29vZ2xlINGA0YPRgdGB0LrQuNC5,R29vZ2xlINGA0YPRgdGB0LrQuNC5,cnUtUlU=,0,0;R29vZ2xlIOaZrumAmuivne+8iOS4reWbveWkp+mZhu+8iQ==,R29vZ2xlIOaZrumAmuivne+8iOS4reWbveWkp+mZhu+8iQ==,emgtQ04=,0,0;R29vZ2xlIOeypOiqnu+8iOmmmea4r++8iQ==,R29vZ2xlIOeypOiqnu+8iOmmmea4r++8iQ==,emgtSEs=,0,0;R29vZ2xlIOWci+iqnu+8iOiHuueBo++8iQ==,R29vZ2xlIOWci+iqnu+8iOiHuueBo++8iQ==,emgtVFc=,0,0;'
                },
                {
                    label: 'voices_EN',
                    value: 'TWljcm9zb2Z0IE1hcmsgLSBFbmdsaXNoIChVbml0ZWQgU3RhdGVzKQ==,TWljcm9zb2Z0IE1hcmsgLSBFbmdsaXNoIChVbml0ZWQgU3RhdGVzKQ==,ZW4tVVM=,1,0;TWljcm9zb2Z0IFBlcm5pbGxlIE9ubGluZSAoTmF0dXJhbCkgLSBOb3J3ZWdpYW4gKEJva23DpWwgIE5vcndheSk=,TWljcm9zb2Z0IFBlcm5pbGxlIE9ubGluZSAoTmF0dXJhbCkgLSBOb3J3ZWdpYW4gKEJva23DpWwgIE5vcndheSk=,bmItTk8=,1,0;TWljcm9zb2Z0IEthbmdrYW5nIC0gQ2hpbmVzZSAoU2ltcGxpZmllZCAgUFJDKQ==,TWljcm9zb2Z0IEthbmdrYW5nIC0gQ2hpbmVzZSAoU2ltcGxpZmllZCAgUFJDKQ==,emgtQ04=,1,0;R29vZ2xlIOaZrumAmuivne+8iOS4reWbveWkp+mZhu+8iQ==,R29vZ2xlIOaZrumAmuivne+8iOS4reWbveWkp+mZhu+8iQ==,emgtQ04=,0,0;R29vZ2xlIOeypOiqnu+8iOmmmea4r++8iQ==,R29vZ2xlIOeypOiqnu+8iOmmmea4r++8iQ==,emgtSEs=,0,0;R29vZ2xlIOWci+iqnu+8iOiHuueBo++8iQ==,R29vZ2xlIOWci+iqnu+8iOiHuueBo++8iQ==,emgtVFc=,0,0;R29vZ2xlIOaXpeacrOiqng==,R29vZ2xlIOaXpeacrOiqng==,amEtSlA=,0,0;R29vZ2xlIERldXRzY2g=,R29vZ2xlIERldXRzY2g=,ZGUtREU=,0,0;R29vZ2xlIFVTIEVuZ2xpc2g=,R29vZ2xlIFVTIEVuZ2xpc2g=,ZW4tVVM=,0,0;R29vZ2xlIFVLIEVuZ2xpc2ggRmVtYWxl,R29vZ2xlIFVLIEVuZ2xpc2ggRmVtYWxl,ZW4tR0I=,0,0;VUsgRW5nbGlzaCBNYWxl,R29vZ2xlIFVLIEVuZ2xpc2ggTWFsZQ==,ZW4tR0I=,0,0;R29vZ2xlIGVzcGHDsW9s,R29vZ2xlIGVzcGHDsW9s,ZXMtRVM=,0,0;R29vZ2xlIGZyYW7Dp2Fpcw==,R29vZ2xlIGZyYW7Dp2Fpcw==,ZnItRlI=,0,0;R29vZ2xlIOCkueCkv+CkqOCljeCkpuClgA==,R29vZ2xlIOCkueCkv+CkqOCljeCkpuClgA==,aGktSU4=,0,0;R29vZ2xlIEJhaGFzYSBJbmRvbmVzaWE=,R29vZ2xlIEJhaGFzYSBJbmRvbmVzaWE=,aWQtSUQ=,0,0;R29vZ2xlIHBvbHNraQ==,R29vZ2xlIHBvbHNraQ==,cGwtUEw=,0,0;R29vZ2xlINGA0YPRgdGB0LrQuNC5,R29vZ2xlINGA0YPRgdGB0LrQuNC5,cnUtUlU=,0,0;R29vZ2xlIE5lZGVybGFuZHM=,R29vZ2xlIE5lZGVybGFuZHM=,bmwtTkw=,0,0;R29vZ2xlIHBvcnR1Z3XDqnMgZG8gQnJhc2ls,R29vZ2xlIHBvcnR1Z3XDqnMgZG8gQnJhc2ls,cHQtQlI=,0,0;R29vZ2xlIGl0YWxpYW5v,R29vZ2xlIGl0YWxpYW5v,aXQtSVQ=,0,0;R29vZ2xlIO2VnOq1reydmA==,R29vZ2xlIO2VnOq1reydmA==,a28tS1I=,0,0;'
                },
                {
                    label: 'voices_EN-win10',
                    value: 'TWljcm9zb2Z0IE1hcmsgTW9iaWxlIC0gRW5nbGlzaCAoVW5pdGVkIFN0YXRlcyk=,TWljcm9zb2Z0IE1hcmsgTW9iaWxlIC0gRW5nbGlzaCAoVW5pdGVkIFN0YXRlcyk=,ZW4tVVM=,1,1;TWljcm9zb2Z0IFppcmEgTW9iaWxlIC0gRW5nbGlzaCAoVW5pdGVkIFN0YXRlcyk=,TWljcm9zb2Z0IFppcmEgTW9iaWxlIC0gRW5nbGlzaCAoVW5pdGVkIFN0YXRlcyk=,ZW4tVVM=,1,0;R29vZ2xlIERldXRzY2g=,R29vZ2xlIERldXRzY2g=,ZGUtREU=,0,0;R29vZ2xlIFVTIEVuZ2xpc2g=,R29vZ2xlIFVTIEVuZ2xpc2g=,ZW4tVVM=,0,0;R29vZ2xlIFVLIEVuZ2xpc2ggRmVtYWxl,R29vZ2xlIFVLIEVuZ2xpc2ggRmVtYWxl,ZW4tR0I=,0,0;R29vZ2xlIFVLIEVuZ2xpc2ggTWFsZQ==,R29vZ2xlIFVLIEVuZ2xpc2ggTWFsZQ==,ZW4tR0I=,0,0;R29vZ2xlIGVzcGHDsW9s,R29vZ2xlIGVzcGHDsW9s,ZXMtRVM=,0,0;R29vZ2xlIGVzcGHDsW9sIGRlIEVzdGFkb3MgVW5pZG9z,R29vZ2xlIGVzcGHDsW9sIGRlIEVzdGFkb3MgVW5pZG9z,ZXMtVVM=,0,0;R29vZ2xlIGZyYW7Dp2Fpcw==,R29vZ2xlIGZyYW7Dp2Fpcw==,ZnItRlI=,0,0;R29vZ2xlIOCkueCkv+CkqOCljeCkpuClgA==,R29vZ2xlIOCkueCkv+CkqOCljeCkpuClgA==,aGktSU4=,0,0;R29vZ2xlIEJhaGFzYSBJbmRvbmVzaWE=,R29vZ2xlIEJhaGFzYSBJbmRvbmVzaWE=,aWQtSUQ=,0,0;R29vZ2xlIGl0YWxpYW5v,R29vZ2xlIGl0YWxpYW5v,aXQtSVQ=,0,0;R29vZ2xlIOaXpeacrOiqng==,R29vZ2xlIOaXpeacrOiqng==,amEtSlA=,0,0;R29vZ2xlIO2VnOq1reydmA==,R29vZ2xlIO2VnOq1reydmA==,a28tS1I=,0,0;R29vZ2xlIE5lZGVybGFuZHM=,R29vZ2xlIE5lZGVybGFuZHM=,bmwtTkw=,0,0;R29vZ2xlIHBvbHNraQ==,R29vZ2xlIHBvbHNraQ==,cGwtUEw=,0,0;R29vZ2xlIHBvcnR1Z3XDqnMgZG8gQnJhc2ls,R29vZ2xlIHBvcnR1Z3XDqnMgZG8gQnJhc2ls,cHQtQlI=,0,0;R29vZ2xlINGA0YPRgdGB0LrQuNC5,R29vZ2xlINGA0YPRgdGB0LrQuNC5,cnUtUlU=,0,0;R29vZ2xlIOaZrumAmuivne+8iOS4reWbveWkp+mZhu+8iQ==,R29vZ2xlIOaZrumAmuivne+8iOS4reWbveWkp+mZhu+8iQ==,emgtQ04=,0,0;R29vZ2xlIOeypOiqnu+8iOmmmea4r++8iQ==,R29vZ2xlIOeypOiqnu+8iOmmmea4r++8iQ==,emgtSEs=,0,0;R29vZ2xlIOWci+iqnu+8iOiHuueBo++8iQ==,R29vZ2xlIOWci+iqnu+8iOiHuueBo++8iQ==,emgtVFc=,0,0;'
                },
                {
                    label: 'voices_sv-SE',
                    value: 'TWljcm9zb2Z0IFNvZmllIE9ubGluZSAoTmF0dXJhbCkgLSBTd2VkaXNoIChTd2VkZW4p,TWljcm9zb2Z0IFNvZmllIE9ubGluZSAoTmF0dXJhbCkgLSBTd2VkaXNoIChTd2VkZW4p,c3YtU0U=,1,0;TWljcm9zb2Z0IEJsZXNzaWNhIE9ubGluZSAoTmF0dXJhbCkgLSBGaWxpcGlubyAoUGhpbGlwcGluZXMp,TWljcm9zb2Z0IEJsZXNzaWNhIE9ubGluZSAoTmF0dXJhbCkgLSBGaWxpcGlubyAoUGhpbGlwcGluZXMp,ZmlsLVBI,1,0;TWljcm9zb2Z0IFl1bnlhbmcgT25saW5lIChOYXR1cmFsKSAtIENoaW5lc2UgKE1haW5sYW5kKQ==,TWljcm9zb2Z0IFl1bnlhbmcgT25saW5lIChOYXR1cmFsKSAtIENoaW5lc2UgKE1haW5sYW5kKQ==,emgtQ04=,1,0;R29vZ2xlIOaZrumAmuivne+8iOS4reWbveWkp+mZhu+8iQ==,R29vZ2xlIOaZrumAmuivne+8iOS4reWbveWkp+mZhu+8iQ==,emgtQ04=,0,0;R29vZ2xlIOeypOiqnu+8iOmmmea4r++8iQ==,R29vZ2xlIOeypOiqnu+8iOmmmea4r++8iQ==,emgtSEs=,0,0;R29vZ2xlIOWci+iqnu+8iOiHuueBo++8iQ==,R29vZ2xlIOWci+iqnu+8iOiHuueBo++8iQ==,emgtVFc=,0,0;R29vZ2xlIOaXpeacrOiqng==,R29vZ2xlIOaXpeacrOiqng==,amEtSlA=,0,0;R29vZ2xlIERldXRzY2g=,R29vZ2xlIERldXRzY2g=,ZGUtREU=,0,0;R29vZ2xlIFVTIEVuZ2xpc2g=,R29vZ2xlIFVTIEVuZ2xpc2g=,ZW4tVVM=,0,0;R29vZ2xlIFVLIEVuZ2xpc2ggRmVtYWxl,R29vZ2xlIFVLIEVuZ2xpc2ggRmVtYWxl,ZW4tR0I=,0,0;VUsgRW5nbGlzaCBNYWxl,R29vZ2xlIFVLIEVuZ2xpc2ggTWFsZQ==,ZW4tR0I=,0,0;R29vZ2xlIGVzcGHDsW9s,R29vZ2xlIGVzcGHDsW9s,ZXMtRVM=,0,0;R29vZ2xlIGZyYW7Dp2Fpcw==,R29vZ2xlIGZyYW7Dp2Fpcw==,ZnItRlI=,0,0;R29vZ2xlIOCkueCkv+CkqOCljeCkpuClgA==,R29vZ2xlIOCkueCkv+CkqOCljeCkpuClgA==,aGktSU4=,0,0;R29vZ2xlIEJhaGFzYSBJbmRvbmVzaWE=,R29vZ2xlIEJhaGFzYSBJbmRvbmVzaWE=,aWQtSUQ=,0,0;R29vZ2xlIHBvbHNraQ==,R29vZ2xlIHBvbHNraQ==,cGwtUEw=,0,0;R29vZ2xlINGA0YPRgdGB0LrQuNC5,R29vZ2xlINGA0YPRgdGB0LrQuNC5,cnUtUlU=,0,0;R29vZ2xlIE5lZGVybGFuZHM=,R29vZ2xlIE5lZGVybGFuZHM=,bmwtTkw=,0,0;R29vZ2xlIHBvcnR1Z3XDqnMgZG8gQnJhc2ls,R29vZ2xlIHBvcnR1Z3XDqnMgZG8gQnJhc2ls,cHQtQlI=,0,0;R29vZ2xlIGl0YWxpYW5v,R29vZ2xlIGl0YWxpYW5v,aXQtSVQ=,0,0;R29vZ2xlIO2VnOq1reydmA==,R29vZ2xlIO2VnOq1reydmA==,a28tS1I=,0,0;'
                },
                {
                    label: 'voices_ko-KR',
                    value: 'TWljcm9zb2Z0IEhlYW1pIE1vYmlsZSAtIEtvcmVhbiAoS29yZWFuKQ==,TWljcm9zb2Z0IEhlYW1pIE1vYmlsZSAtIEtvcmVhbiAoS29yZWFuKQ==,a28tS1I=,1,1;R29vZ2xlIERldXRzY2g=,R29vZ2xlIERldXRzY2g=,ZGUtREU=,0,0;R29vZ2xlIFVTIEVuZ2xpc2g=,R29vZ2xlIFVTIEVuZ2xpc2g=,ZW4tVVM=,0,0;R29vZ2xlIFVLIEVuZ2xpc2ggRmVtYWxl,R29vZ2xlIFVLIEVuZ2xpc2ggRmVtYWxl,ZW4tR0I=,0,0;R29vZ2xlIFVLIEVuZ2xpc2ggTWFsZQ==,R29vZ2xlIFVLIEVuZ2xpc2ggTWFsZQ==,ZW4tR0I=,0,0;R29vZ2xlIGVzcGHDsW9s,R29vZ2xlIGVzcGHDsW9s,ZXMtRVM=,0,0;R29vZ2xlIGVzcGHDsW9sIGRlIEVzdGFkb3MgVW5pZG9z,R29vZ2xlIGVzcGHDsW9sIGRlIEVzdGFkb3MgVW5pZG9z,ZXMtVVM=,0,0;R29vZ2xlIGZyYW7Dp2Fpcw==,R29vZ2xlIGZyYW7Dp2Fpcw==,ZnItRlI=,0,0;R29vZ2xlIOCkueCkv+CkqOCljeCkpuClgA==,R29vZ2xlIOCkueCkv+CkqOCljeCkpuClgA==,aGktSU4=,0,0;R29vZ2xlIEJhaGFzYSBJbmRvbmVzaWE=,R29vZ2xlIEJhaGFzYSBJbmRvbmVzaWE=,aWQtSUQ=,0,0;R29vZ2xlIGl0YWxpYW5v,R29vZ2xlIGl0YWxpYW5v,aXQtSVQ=,0,0;R29vZ2xlIOaXpeacrOiqng==,R29vZ2xlIOaXpeacrOiqng==,amEtSlA=,0,0;R29vZ2xlIO2VnOq1reydmA==,R29vZ2xlIO2VnOq1reydmA==,a28tS1I=,0,0;R29vZ2xlIE5lZGVybGFuZHM=,R29vZ2xlIE5lZGVybGFuZHM=,bmwtTkw=,0,0;R29vZ2xlIHBvbHNraQ==,R29vZ2xlIHBvbHNraQ==,cGwtUEw=,0,0;R29vZ2xlIHBvcnR1Z3XDqnMgZG8gQnJhc2ls,R29vZ2xlIHBvcnR1Z3XDqnMgZG8gQnJhc2ls,cHQtQlI=,0,0;R29vZ2xlINGA0YPRgdGB0LrQuNC5,R29vZ2xlINGA0YPRgdGB0LrQuNC5,cnUtUlU=,0,0;R29vZ2xlIOaZrumAmuivne+8iOS4reWbveWkp+mZhu+8iQ==,R29vZ2xlIOaZrumAmuivne+8iOS4reWbveWkp+mZhu+8iQ==,emgtQ04=,0,0;R29vZ2xlIOeypOiqnu+8iOmmmea4r++8iQ==,R29vZ2xlIOeypOiqnu+8iOmmmea4r++8iQ==,emgtSEs=,0,0;R29vZ2xlIOWci+iqnu+8iOiHuueBo++8iQ==,R29vZ2xlIOWci+iqnu+8iOiHuueBo++8iQ==,emgtVFc=,0,0;'
                }
            ],
            batteryPercent: this.form.browserApi.batteryPercentage * 100
        }
    },
    watch:{
        form:{
            deep: true,
            handler(val){
                this.$emit('update:form',val)
            }
        },
        'form.canvasDefType'(val){
            if(val == 'NOISEA' || val == 'BLOCK' || val == 'NOISEB' || val == 'NOISEC'){
                this.canvasDefTypeActive = val
            }
        },
        batteryPercent(val){
            this.form.browserApi.batteryPercentage = val / 100
        }
    },
    mounted(){
        if(this.openDialog){
            this.form.webgl.vendor = this.vendor[0].id
            this.vendorChange(this.form.webgl.vendor)
            this.randomVoice()
        }
        if(!Number.isFinite(Number(this.form.webgl.vendor))){
            this.form.webgl.vendor = this.vendor.filter(item => item.vendor === this.form.webgl.vendor)[0].id
            let vendorIndex = this.vendor.findIndex(item => item.id === this.form.webgl.vendor)
            this.rendererList = this.renderer.filter(item => item.vendor === this.vendor[vendorIndex].vendor)
            this.form.webgl.renderer = this.rendererList.filter(item => item.renderer === this.form.webgl.renderer)[0].id
        }
    },
    methods:{
        setFont(){
            this.dialog = true
            let arr = JSON.parse(window.ChromeSDK.myfunc("GetSysFonts"))
            this.fontTable = arr
            setTimeout(() => {
                this.form.fontSetting.fontList.map(row => {
                    this.$refs.multipleTable.toggleRowSelection(row, true)
                })
            },1000)
        },
        handleSelectionChange(val){
            this.multipleSelection = val
        },
        selectedFont(){
            this.form.fontSetting.fontList = this.multipleSelection
            this.dialog = false
        },
        vendorChange(val){
            let vendorIndex = this.vendor.findIndex(item => item.id === val)
            this.rendererList = this.renderer.filter(item => item.vendor === this.vendor[vendorIndex].vendor)
            this.randomRender()
        },
        randomRender(){
            let index = Math.floor(Math.random() * this.rendererList.length)
            this.form.webgl.renderer = this.rendererList[index].id
        },
        randomVoice(){
            let data = deepClone(this.voiceList)
            data.splice(0,1)
            let index = Math.floor(Math.random() * data.length)
            this.form.browserApi.speechVoicesList = data[index].value
        }
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep{
        .el-dialog{
            border-radius: 10px;
            margin-top: calc(50% - 30vh);
            height: auto;
            .el-dialog__body{
                padding: 0 20px 20px 20px;
                .table-wrap{
                    height: 50vh;
                    overflow-y:auto;
                    &::-webkit-scrollbar{
                    width: 5px;
                    }
                    &::-webkit-scrollbar-thumb{
                    border-radius: 4px;
                    background-color: #606266;
                    }
                }
            }
            .el-dialog__footer{
                .el-button--primary{
                    background-color: #ff6e00;
                    border-color: #ff6e00;
                }
            }
            
        }
        .el-slider__bar{
            background: linear-gradient(90deg,#ff6e00,#eefb40,#bdf959);
            background-color: unset;
        }
        .el-slider__button{
            width: 20px;
            height: 20px;
            border: unset !important;
            background-image: url('~@/assets/imgs/refresh.png');
            background-size: 100% 100%;
            padding: 16px;
        }
    }
    .switch-wrap-label{
    font-size: 16px;
}
.switch-wrap{
    border-radius: 10px;
    border: 1px solid #ff6e00;
    padding: 20px;
}
</style>