<template>
  <div>
    <el-form v-model="form">
        <el-row>
            <el-col :span="11">
                <el-form-item label="默认首页：">
                    <el-input v-model="form.startUrl" style="width:100%"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="11" :offset="2">
                <el-form-item label="启动内核：">
                    <el-select v-model="form.kernelVer" style="width:100%">
                        <el-option v-for="item in kernelVerList" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        
        
        <el-form-item label="自定义启动浏览器参数：">
            <el-input v-model="form.browserParams"></el-input>
        </el-form-item>
        <el-form-item label="自定义DNS：">
            <el-input v-model="form.customDns"></el-input>
        </el-form-item>
        <el-form-item>
            <div class="switch-wrap-label">本地缓存</div>
            <div class="switch-wrap">
                <el-row type="flex" align="middle">
                    <el-col :span="11">
                        <el-switch v-model="form.localCache.deleteCache" active-text="启动浏览器前删除缓存文件"></el-switch>
                    </el-col>
                    <el-col :span="11" :offset="2">
                        <el-switch v-model="form.localCache.clearHistory" active-text="浏览器关闭时删除历史记录"></el-switch>
                    </el-col>
                </el-row>
                <el-row type="flex" align="middle">
                    <el-col :span="11">
                        <el-switch v-model="form.localCache.deleteCookie" active-text="启动浏览器前删除缓存Cookie"></el-switch>
                    </el-col>
                    <el-col :span="11" :offset="2">
                        <el-switch v-model="form.localCache.clearCache" active-text="浏览器关闭时清理文件缓存"></el-switch>
                    </el-col>
                </el-row>
            </div>
        </el-form-item>
        <el-form-item>
            <div class="switch-wrap-label">同步设置</div>
            <div class="switch-wrap">
                <el-row type="flex" align="middle">
                    <el-col :span="11">
                        <el-switch v-model="form.synSettings.synLastTag" active-text="同步近期标签页"></el-switch>
                    </el-col>
                    <el-col :span="11" :offset="2">
                        <el-switch v-model="form.synSettings.synCookie" active-text="同步保存Cookie"></el-switch>
                        <el-checkbox v-model="form.synSettings.extensionData" label="" style="margin-left:20px">扩展数据</el-checkbox>
                    </el-col>
                </el-row>
                <el-row type="flex" align="middle">
                    <el-col :span="11">
                        <el-switch v-model="form.synSettings.synHistory" active-text="同步历史记录"></el-switch>
                    </el-col>
                    <el-col :span="11" :offset="2">
                        <el-switch v-model="form.synSettings.synBookmark" active-text="同步保存书签"></el-switch>
                    </el-col>
                </el-row>
                <el-row type="flex" align="middle">
                    <el-col :span="11">
                        <el-switch v-model="form.synSettings.synKeepKey" active-text="同步保存密码"></el-switch>
                    </el-col>
                    <el-col :span="11" :offset="2">
                        <el-switch v-model="form.synSettings.synExtension" active-text="同步扩展插件"></el-switch>
                    </el-col>
                </el-row>
            </div>
        </el-form-item>
        <el-row>
            <el-col :span="11">
                <el-form-item>
                    <el-switch v-model="form.browserSettings.pepperFlash" active-text="启动papperflash插件"></el-switch>
                </el-form-item>
            </el-col>
            <el-col :span="11" :offset="2">
                <el-form-item>
                    <el-switch v-model="form.browserSettings.mediaStream" active-text="启用媒体WebRtc音频视频流"></el-switch>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="11">
                <el-form-item>
                    <el-switch v-model="form.browserSettings.webkitSpeech" active-text="启用语音输入x-webkit-speech"></el-switch>
                </el-form-item>
            </el-col>
            <el-col :span="11" :offset="2">
                <el-form-item>
                    <el-switch v-model="form.browserSettings.fakeUiForMedia" active-text="通过选择媒体流的默认设备绕过媒体流信息栏"></el-switch>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="11">
                <el-form-item>
                    <el-switch v-model="form.browserSettings.gpuAndPepper3D" active-text="启用GPU插件和papper3D渲染"></el-switch>
                </el-form-item>
            </el-col>
            <el-col :span="11" :offset="2">
                <el-form-item>
                    <el-switch v-model="form.browserSettings.ignoreCertErrors" active-text="忽略网站证书错误"></el-switch>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="11">
                <el-form-item>
                    <el-switch v-model="form.browserSettings.audioMute" active-text="音频静音"></el-switch>
                </el-form-item>
            </el-col>
            <el-col :span="11" :offset="2">
                <el-form-item>
                    <el-switch v-model="form.browserSettings.disableWebSecurity" active-text="不强制执行同一源策略"></el-switch>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="11">
                <el-form-item>
                    <el-switch v-model="form.browserSettings.disablePdf" active-text="禁用PDF扩展"></el-switch>
                </el-form-item>
            </el-col>
            <el-col :span="11" :offset="2">
                <el-form-item>
                    <el-switch v-model="form.browserSettings.touchEvents" active-text="启用对触摸事件功能检测的支持"></el-switch>
                </el-form-item>
            </el-col>
        </el-row>
        <el-form-item>
            <el-switch v-model="form.browserSettings.hyperlinkAuditing" active-text="链接审计hyperlinkauditing可用于追踪网站链接的点击次数"></el-switch>
        </el-form-item>
        
    </el-form>
  </div>
</template>

<script>
export default {
    props:['form','kernelVerList','openDialog'],
    watch:{
        form:{
            deep: true,
            handler(val){
                this.$emit('update:form',val)
            }
        }
    },
    data(){
        return{

        }
    },
    mounted(){
        if(this.openDialog){
            this.form.kernelVer = this.kernelVerList[0]
        }
    },
    methods:{
        
    }
}
</script>

<style lang="scss" scoped>
.switch-wrap-label{
    font-size: 16px;
}
.switch-wrap{
    border-radius: 10px;
    border: 1px solid #ff6e00;
    padding: 20px;
}
</style>