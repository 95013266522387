<template>
  <div>
    <el-form ref="form" :model="formSearch" label-width="30%" label-position="left">
      <el-row
        type="flex"
        align="middle"
        justify="space-between"
        style="margin-bottom: 2%"
        class="search-wrap"
      >
        <el-col :span="8">
          <el-form-item label="店铺名称:">
            <el-input v-model="formSearch.bname"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" :offset="2">
          <el-form-item label="使用者:">
            <el-input v-model="formSearch.company_user_true_name"></el-input>
          </el-form-item>
        </el-col>
        
        <el-col :span="8" style="display: flex; justify-content: flex-end">
          <el-button class="fill-btn" @click="search(formSearch)">搜索</el-button>
          <el-button class="un-fill-btn" @click="resetForm(formSearch)">重置</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-button class="fill-btn" @click="openAdd" :loading="addLoading">新增</el-button>
    <el-table
      :data="tableData"
      style="width: calc(100% - 2px)"
      height="70vh"
      row-class-name="tableRowClassName"
      v-loading="loadingShow || editLoading"
    >
      <el-table-column type="index" label="序号" align="center" width="100px">
      </el-table-column>
      <el-table-column prop="bname" label="店铺名称" align="center">
      </el-table-column>
      <el-table-column prop="browser_group_name" label="分组" align="center" v-if="roles[0] === 'admin'">
      </el-table-column>
      <el-table-column prop="company_user_true_name" label="使用者" align="center">
      </el-table-column>
      <el-table-column prop="is_use" label="状态" align="center">
        <template slot-scope="scope">
          <div class="off-status" v-if="scope.row.is_use === 2">下线</div>
          <div class="using-status" v-if="scope.row.is_use === 1">上线</div>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注" align="center">
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" align="center">
      </el-table-column>
      <el-table-column prop="last_time" label="最后使用时间" align="center">
      </el-table-column>
      <el-table-column label="操作" align="center" width="200">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" content="启动" placement="top">
            <el-button class="svg-btn" :loading="scope.row.loadingIcon" :disabled="scope.row.loadingIcon" v-if="scope.row.is_use === 2">
              <svg-icon
                icon-class="start"
                @click="start(scope.row)">
              </svg-icon>
            </el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="暂停" placement="top">
            <el-button class="svg-btn" :loading="scope.row.loadingIcon" :disabled="scope.row.loadingIcon" v-if="scope.row.is_use === 1">
              <svg-icon
                icon-class="pause"
                @click="pause(scope.row)">
              </svg-icon>
            </el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="编辑" placement="top">
            <el-button class="svg-btn">
              <svg-icon
                icon-class="edit"
                @click="openEditBrowser(scope.row.id,scope.row.browser_group_id,scope.row.company_user_id)">
              </svg-icon>
            </el-button>
          </el-tooltip>
          
          <el-tooltip class="item" effect="dark" content="更多操作" placement="top">
            <el-dropdown trigger="click">
            <span style="cursor:pointer">
              <i class="el-icon-more el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item @click.native="openFreed(scope.row)" v-if="scope.row.is_use === 1"><i class="el-icon-remove-outline el-icon--right"></i>释放</el-dropdown-item> -->
              <el-dropdown-item @click.native="openFreed(scope.row)" :disabled="scope.row.is_use !== 1"><i class="el-icon-remove-outline el-icon--right"></i>释放</el-dropdown-item>
              <el-dropdown-item @click.native="cookieOpen(scope.row)"><i class="el-icon-document-add el-icon--right"></i>导入cookie</el-dropdown-item>
              <el-dropdown-item @click.native="deleteDialog = true;deleteId = scope.row.id;deleteInfo = scope.row.bname"><i class="el-icon-delete el-icon--right"></i>删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
            
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    
    <el-dialog :visible.sync="addDialog" width="70vw" class="add-dialog" @close="editLoading = false;addLoading = false;openDialog = false">
      <el-tabs tab-position="top" style="height: 50vh;margin-bottom: 30px;">
        <el-tab-pane label="快速配置" v-if="addDialog">
          <FastConfig :form.sync="form" :groupData.sync="groupData" :accountData.sync="accountData" :groupId.sync="groupId" :userId.sync="userId" :browserVerList.sync="browserVerList" :uaList.sync="uaList" :resourcesData.sync="resourcesData" :selectedId.sync="selectedId" :openDialog.sync="openDialog"/>
        </el-tab-pane>
        <el-tab-pane label="高级配置" v-if="addDialog">
          <el-tabs tab-position="left" style="height: 50vh;">
            <el-tab-pane label="基本配置">
              <Base :form.sync="form" :groupData.sync="groupData" :accountData.sync="accountData" :groupId.sync="groupId" :userId.sync="userId" :browserVerList.sync="browserVerList" :uaList.sync="uaList" :resourcesData.sync="resourcesData" :selectedId.sync="selectedId"  :openDialog.sync="openDialog" />
            </el-tab-pane>
            <el-tab-pane label="浏览器配置">
              <Browser :form.sync="form" :kernelVerList.sync="kernelVerList" :openDialog.sync="openDialog" />
            </el-tab-pane>
            <el-tab-pane label="指纹配置">
              <Finger :form.sync="form" :vendor.sync="vendor" :renderer.sync="renderer" :openDialog.sync="openDialog" />
            </el-tab-pane>
            <el-tab-pane label="硬件配置">
              <Hardware :form.sync="form" :openDialog.sync="openDialog" />
            </el-tab-pane>
            <el-tab-pane label="端口防护">
              <Port :form.sync="form" />
            </el-tab-pane>
            <el-tab-pane label="辅助启动配置">
              <Assist :form.sync="form" />
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
      </el-tabs>
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialog = false;">取 消</el-button>
        <el-button type="primary" @click="addShop" :loading="addDialogLoading">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="cookieDialog" title="导入Cookie" width="40vw">
      <el-form style="padding-top:20px">
        <el-form-item label="店铺名称">
          <el-input v-model="cookieForm.bname" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="Cookie">
          <el-input type="textarea" rows="5" v-model="cookieForm.cookie"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="un-fill-btn" @click="cookieDialog = false;">取 消</el-button>
        <el-button class="fill-btn" type="primary" @click="importCookie">导 入</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="freedDialog" title="释放配置文件">
      <div class="freed-title">
        <i class="el-icon-info"></i>释放配置文件
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="un-fill-btn" @click="freedDialog = false;">取 消</el-button>
        <el-button class="fill-btn" type="primary" @click="freedBrowser">释 放</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="deleteDialog" title="删除店铺">
      <div class="freed-title">
        <i class="el-icon-info"></i>确定 删除 店铺【{{deleteInfo}}】吗？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="un-fill-btn" @click="deleteDialog = false;">取 消</el-button>
        <el-button class="fill-btn" type="primary" @click="shopDelete">删 除</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {addShop,shopList,shopEdit,shopDelete,freedBrowser,subaccList,groupList,getBrowserVerList,getBrowserConfig,getResources,resourcesArea} from '@/api/index'
import Fuse from "fuse.js";
import {rsaEncrypt} from '@/utils/jsencrypt'
import md5 from 'js-md5'
import {mapGetters} from 'vuex'
import {windowRegistData} from '@/utils/client'
import Base from './components/Base.vue'
import Browser from './components/Browser.vue'
import Finger from './components/Finger.vue'
import Hardware from './components/Hardware.vue'
import Port from './components/Port.vue'
import Assist from './components/Assist.vue'
import FastConfig from './components/FastConfig.vue'
import {deepClone} from '@/utils/common'
export default {
  components:{
    Base,
    Browser,
    Finger,
    Hardware,
    Port,
    Assist,
    FastConfig
  },
  data() {
    return {
      formSearch: {
        bname: '',
        company_user_true_name: ''
      },
      tableData: [],
      loadingShow: false,
      addLoading: false,
      addDialog: false,
      subAccOptions: [],
      groupOptions: [],
      editLoading: false,
      addDialogLoading: false,
      freedDialog: false,
      freedId: '',
      deleteDialog: false,
      deleteId: '',
      deleteInfo: '',
      selectedId: null,
      // 基本设置
      form: {
        name: '',
        notes: '',
        os: {
          osType: ['Windows'],
          browserVer: []
        },
        userAgent: '',
        webRtc: {
          type: "FAKE",
          fillOnStart: true,
          wanSet: true,
          lanSet: true,
          publicIp: "",
          localIps: [],
        },
        resources_id: 0,
        proxyServer: {
            type: "NOPROXY",
            host: "",
            port: "",
            username: "",
            password: ""
        },
        screenWidth: '1024',
        screenHeight: '768',
        pixelRatio: '1',
        screenAutoDetected: true,
        langHdr: 'en-US',
        acceptLanguage: 'en-US,en;q=0.9',
        langBasedOnIp: true,
        hardwareConcurrency: '2',
        deviceMemory: '2',
        deviceMemoryCPUAuto: true, 
        mobileEmulation: false,
        deviceType: '0',
        // 浏览器配置
        startUrl: '',
        kernelVer: '',
        browserParams: '',
        customDns: '',
        browserSettings: {
          pepperFlash: false,
          mediaStream: true,
          webkitSpeech: true,
          fakeUiForMedia: true,
          gpuAndPepper3D: true,
          ignoreCertErrors: false,
          audioMute: false,
          disableWebSecurity: false,
          disablePdf: false,
          touchEvents: false,
          hyperlinkAuditing: true,
        },
        localCache: {
          deleteCache: false,
          deleteCookie: false,
          clearCache: false,
          clearHistory: false,
        },
        synSettings:{
          synCookie: true,
          extensionData: false,
          synLastTag: true,
          synBookmark: true,
          synHistory: true,
          synExtension: false,
          synKeepKey: true,
        },
        // 浏指纹配置
        maskFonts: true,
        fontSetting:{
          clientRects: true,
          fontList: []
        },
        canvasDefType: 'NOISEB',
        audio:{
          noise: true,
        },
        webgl:{
          noise: true,
          metadata: true,
          vendor: '',
          renderer: '',
        },
        timeZoneFillOnStart: true,
        timeZone: '',
        browserApi: {
          setBatteryStatus: false,
          isCharging: true,
          chargingTime: '0',
          drainsTime: 'Infinity',
          batteryPercentage: 1,
          setWebBluetooth: false,
          setBluetoothAdapter: false,
          autoGeoIp: true,
          setLatitude: false,
          setLongitude: false,
          setAccuracy: false,
          latitude: '',
          longitude: '',
          accuracy: '',
          speechSynthesis: true,
          speechVoicesList: '' // 变动
        },
        // 硬件配置
        mediaDevices: {
          setMediaDevices: true,
          use_name: false,
          videoInputs: {
            count: 1,
            device1: {
              label: '',
              deviceId: '',
              groupId: ''
            },
          },
          audioInputs: {
            count: 1,
            device1: {
              label: '',
              deviceId: '',
              groupId: ''
            },
          },
          audioOutputs: {
            count: 1,
            device1: {
              label: '',
              deviceId: '',
              groupId: ''
            },
          },
        },
        leakProof: {
          computerName: {
            isUse: true,
            value: '',
          },
          macAddress:{
            isUse: true,
            value: ''
          }
        },
        // 端口防护
        remoteDebug:{
          bindAllDebug: false,
          debuggingPort: '',
          logLevels: '99',

        },
        otherProtection:{
          setPortScan: true,
          localPortsExclude: ''
        },
        // 辅助启动配置
        cmdcfg:{
          openCommandLine: false,
          commandLine: '',
          fillOnStart: false // 变动
        }
      },
      
      
      groupId: '',
      userId: '',
      groupData: [],
      accountData: [],
      browserVerList: [],
      kernelVerList: [],
      uaList: {},
      vendor: [],
      renderer: [],
      resourcesData: [],

      browId: null,
      copyForm: {},
      openDialog: false,
      cookieDialog: false,
      cookieForm:{
        cookie: ''
      }
    };
  },
  computed:{
    ...mapGetters(['userInfo','clientData','isRefreshData','roles'])
  },
  watch:{
    async isRefreshData(newValue){
      if(newValue){
        await this.getShopList()
        if(this.roles[0] === 'admin'){
          await this.getSubAccList()
          await this.getGroupList()
        }
        this.$store.commit('index/SET_ISREFRESH',false)
        this.$message({
          type: 'success',
          message: '数据刷新成功'
        })
      }
    },
    clientData:{
      deep: true,
      handler(val){
        if(val.type === 'stop' && val.error === 0){
          this.getShopList()
        }
      }
    },
    form:{
      deep:true,
      handler(val){
        console.log('FORM-CHANGE',val)
      }
    },
  },
  created(){
    this.getShopList()
    if(this.roles[0] === 'admin'){
        this.getGroupList()
    }
    this.copyForm = deepClone(this.form)
  },
  methods: {
    getShopList(){
      this.loadingShow = true
      return new Promise((resolve,reject) => {
        this.$store.dispatch('index/getShopList').then(data => {
          data.lists.map(item => {
            item.loadingIcon = false
          })
          this.tableData = data.lists
          console.log(data.lists)
          this.loadingShow = false
          resolve(data)
        }).catch(err => {
          this.loadingShow = false
          reject(err)
        })
      })
    },
    async start(row){
      try{
        await this.$store.dispatch('index/getInfo')
        if(row.is_open === 1){
          row.loadingIcon = true
          window.ChromeSDK.myfunc('StartProfileId',row.uuid,true);
          let data = await windowRegistData()
          if(data.error === 0){
            this.$message.success('启动浏览器成功！')
          }else{
            this.$message.error('启动浏览器失败！')
          }
          row.loadingIcon = false
          await this.getShopList()
        }else{
          this.$message.error('资源已到期，请续费资源或在编辑配置文件里面替换资源')
        }
      }catch(err){
        row.loadingIcon = false
        console.log(err)
      }
    },
    async pause(row){
      try{
        await this.$store.dispatch('index/getInfo')
        row.loadingIcon = true
        window.ChromeSDK.myfunc('StopProfileId',row.uuid);
        let data = await windowRegistData()
        if(data.error === 0){
          this.$message.success('关闭浏览器成功！')
        }else{
          this.$message.error('关闭浏览器失败！')
        }
        row.loadingIcon = false
        await this.getShopList()
      }catch(err){
        row.loadingIcon = false
        console.log(err)
      }
    },
    search(form){
      if(form.bname === '' && form.company_user_true_name === ''){
        this.getShopList()
      }else{
        let fuse = new Fuse(this.tableData, {
          shouldSort: true,
          threshold: 0.4,
          location: 0,
          distance: 100,
          maxPatternLength: 32,
          minMatchCharLength: 1,
          keys: ['bname','company_user_true_name']
        });
        this.tableData = fuse.search({
          $or: [{ bname: form.bname }, { company_user_true_name: form.company_user_true_name }]
        }).map(item => {
          return item.item
        })
      }
    },
    resetForm(form){
      this.getShopList()
      Object.keys(form).forEach(item => {
        form[item] = ''
      })
    },
    async getSubAccList(){
      try{
        this.subAccOptions = []
        let {result} = await subaccList()
        let data = this.$rsaDecrypt(result)
        data.lists.forEach((item,index) => {
          let obj = {}
          obj.label = item.true_name
          obj.value = item.id
          this.subAccOptions.push(obj)
        })
      }catch(err){
        console.log(err)
      }
    },
    async getGroupList(){
      try{
        let {result} = await groupList()
        let data = this.$rsaDecrypt(result)
        data.lists.forEach((item,index) => {
          let obj = {}
          obj.label = item.name
          obj.value = item.id
          this.groupOptions.push(obj)
        })
      }catch(err){
        console.log(err)
      }
    },
    async getBrowData(){
      try{
        if(this.roles[0] === 'admin'){

          let acc = await subaccList()
          let data1 = this.$rsaDecrypt(acc.result)
          this.accountData = data1.lists

          let group = await groupList()
          let data2 = this.$rsaDecrypt(group.result)
          this.groupData = data2.lists

        }

        let browsVlist = ChromeSDK.myfunc('GetSysInfo')
        this.kernelVerList = JSON.parse(browsVlist).core.split(',')

        let browV = await getBrowserVerList()
        let data3 = this.$rsaDecrypt(browV.result)
        this.browserVerList = Object.values(data3.lists.browser_ver).reverse().map(item => item.toString())

        let resources = await getResources()
        let data4 = this.$rsaDecrypt(resources.result)
        this.resourcesData = data4.lists
        console.log('资源列表',data4.lists)
        
        this.uaList = data3.lists.user_agent
        this.vendor = data3.lists.webgl_vendor
        this.renderer = data3.lists.webgl_renderer
        

      }catch(err){
        console.log(err)
      }
    },
    async openAdd(){
      try{
        this.openDialog = true
        this.groupId = ''
        this.userId = ''
        this.form = deepClone(this.copyForm)
        this.addLoading = true
        await this.getBrowData()
        this.form.os.browserVer = [this.browserVerList[0]]
        this.browId = 0
        this.addDialog = true
      }catch(err){
        this.openDialog = false
        this.addLoading = false
        this.addDialog = false
      }
    },
    addShop(){
      this.addDialogLoading = true
      let addForm = {
        config: this.form,
        id: this.browId,
        name: this.form.name,
        browser_group_id: this.roles[0] === 'admin' ? this.groupId : 0,
        // company_user_id: this.roles[0] === 'admin' ? this.userId : 0,
      }
      if(this.roles[0] === 'admin'){
        addForm.company_user_id = this.roles[0] === 'admin' ? this.userId : 0
      }else{
        addForm.company_user_id = this.userInfo.user_id
      }
      console.log(addForm)
      let requestData = this.processRSA(addForm, "SIJa%$%3hu12hu(*1278")
      addShop(requestData).then(res => {
        this.addDialog = false
        this.editLoading = false
        this.addLoading = false
        this.$message.success(res.msg)
        this.addDialogLoading = false
        this.getShopList()
      }).catch(err => {
        this.addDialogLoading = false
        console.log(err)
      })
    },
    processRSA(form, sign_key){
      let s = {
        sign_key: sign_key,
        params: "",
      };
      Object.values(form).forEach(item => {
          if(item instanceof Object){

          }else{
            s.params = `${s.params}${item}`
          }
      })
      let requestData = {
          s: `${md5(s.params + s.sign_key)}`,
          data: rsaEncrypt(JSON.stringify(form)),
      };
      return requestData
    },
    async openEditBrowser(id,group,user){
      try{
        this.editLoading = true
        await this.getBrowData()
        let requestData = this.$rsaEncryptForm({id:id}, "SIJa%$%3hu12hu(*1278")
        let {result} = await getBrowserConfig(requestData)
        let data = this.$rsaDecrypt(result)
        let config = JSON.parse(data.config).Body
        console.log('CONFIG',config)
        this.form = config
        this.browId = id
        this.groupId = group,
        this.userId = user,
        this.addDialog = true
      }catch(err){
        this.editLoading = false
        this.addDialog = false
      }
    },
    cookieOpen(row){
      this.cookieForm = row
      this.cookieDialog = true
    },
    async importCookie(){
      window.ChromeSDK.myfunc('ImportCookie',this.cookieForm.uuid,this.$Base64.encode(this.cookieForm.cookie));
      let data = await windowRegistData()
      if(data.error === 0){
        this.cookieDialog = false
      }
      this.$message({
        type:data.error === 0 ? 'success'  : 'error',
        message: data.error === 0 ? '导入Cookie成功！' : '导入Cookie失败！'
      })
    },
    
    shopDelete(){
      let requestData = this.$rsaEncryptForm({id:this.deleteId}, "SIJa%$%3hu12hu(*1278")
      shopDelete(requestData).then(res => {
        this.$message.success(res.msg)
        this.deleteDialog = false
        this.getShopList()
      }).catch(err => {
        console.log(err)
      })
    },
    openFreed(row){
      this.freedId = row.id
      this.freedDialog = true
    },
    freedBrowser(){
      let requestData = this.$rsaEncryptForm({id:this.freedId}, "SIJa%$%3hu12hu(*1278")
      freedBrowser(requestData).then(res => {
        this.freedDialog = false
        this.getShopList()
      }).catch(err => {
        conosle.log(err)
      })
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/common.scss';

::v-deep .el-dialog {
  border-radius: 10px;
  .el-dialog__header {
    padding: 54px 20px 0px 70px;
  }
  .el-dialog__body {
    padding: 0 70px 60px 70px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
    p {
      margin-bottom: 36px;
    }
  }
}
::v-deep  .svg-btn{
  border: unset;
  background: transparent;
  padding: unset;
  margin-left: unset;
}

::v-deep {
  .add-dialog{
    .el-dialog__body{
      padding: 0 20px 20px 20px;
      .el-input__inner,.el-textarea__inner{
        background-color: #FFF;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        height: auto !important;
      }
      .el-input.is-disabled .el-input__inner {
        background-color: #F5F7FA;
        border-color: #E4E7ED;
        color: #C0C4CC;
        cursor: not-allowed;
        height: auto !important;
      }
      .el-form-item__label{
        font-size: 16px;
        color: #4C4C4C;
        font-weight: normal;
      }
      .el-tabs__nav-wrap.is-left{
        margin-right: 20px;
        &::after{
          background-color: unset;
        }
      }
      .el-tabs__nav.is-left{
        background-color: #fef6f0 !important;
        border-radius: 10px;
        .el-tabs__item{
          padding: 20px 40px;
          height: unset;
          line-height: 1;
        }
        .is-active{
          background-color: #ffefe4;
        }
      }
      .el-input__inner,.el-textarea__inner{
        background-color: #fef6f0;
        border: unset;
      }
      .el-tabs__item{
        font-size: 18px;
      }
      .el-tabs__item:hover,.el-tabs__item.is-active{
        color: #ff6e00;
      }
      .el-tabs__active-bar{
        background-color: #ff6e00;
      }
      .el-tabs__nav{
        transform: translateX(-50%) !important;
        left: 50%;
      }
      .el-tabs__content{
        height: 100%;
        overflow: auto;
        &::-webkit-scrollbar{
          width: 5px;
        }
        &::-webkit-scrollbar-thumb{
          border-radius: 4px;
          background-color: #606266;
        }
        .el-form{
          width: 98%;
          .el-checkbox__label,.el-radio__label,.el-switch__label > span{
            font-size: 16px;
          }
          .shake{
            text-align: center;
            color: #ff6e00;
            cursor: pointer;
          }
          .el-switch.is-checked .el-switch__core,
          .el-checkbox__input.is-checked .el-checkbox__inner,
          .el-button--primary{
            background-color:#ff6e00;
            border-color: #ff6e00;
          }
          .el-button--primary.is-disabled{
            background-color: #e99454;
            border-color: #e99454;
          }
          .el-checkbox__inner:hover,
          .el-checkbox__input.is-focus .el-checkbox__inner,
          .el-slider__bar,
          .el-slider__button,
          .el-input-number__decrease:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled), .el-input-number__increase:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled){
            border-color: #ff6e00;
          }
          .el-switch__label.is-active,
          .el-checkbox__input.is-checked+.el-checkbox__label,
          .el-input-number__increase:hover,
          .el-input-number__decrease:hover{
            color: #ff6e00;
          }
        }
      }
    }
    .el-dialog__footer{
      .el-button--primary{
        background-color: #ff6e00;
        border-color: #ff6e00;
      }
    }
    .item-no-label{
        padding-top: 40px;
        margin-bottom: 22px;
    }
  }
}
.freed-title{
  font-size: 20px;
  color: #ff6e00;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  i{
    padding-right: 10px;
  }
}
</style>
