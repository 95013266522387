<template>
  <div>
    <el-form v-model="form">
        <el-form-item label="显示名称：">
            <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-row>
            <el-col :span="11">
                <el-form-item label="分组：" v-if="roles[0] === 'admin'">
                    <el-select v-model="group" style="width:100%">
                        <el-option v-for="item in groupData" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="11" :offset="2">
                <el-form-item label="所属用户：" v-if="roles[0] === 'admin'">
                    <el-select v-model="user" style="width:100%">
                        <el-option v-for="item in accountData" :key="item.id" :label="item.true_name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <!-- <el-form-item label="操作系统：">
            <el-select v-model="form.os.osType">
                <el-option label="Windows" value="Windows"></el-option>
                <el-option label="Linux" value="Linux"></el-option>
            </el-select>
        </el-form-item> -->
        <el-form-item label="浏览器版本：">
            <el-select multiple v-model="form.os.browserVer" @change="genSelect" style="width:100%">
                <el-option v-for="item in browserVerList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
        </el-form-item>
        <el-row type="flex" align="middle">
            <el-col :span="21">
                <el-form-item label="User-Agent：">
                    <el-input v-model="form.userAgent" type="textarea" :rows="5"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="2" :offset="1">
                <i class="el-icon-refresh pr-5 shake"></i><span class="shake" @click="genSelect()">换一换</span>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="11">
                <el-form-item label="WebRtc：">
                    <el-select v-model="form.webRtc.type" placeholder="返回指定的IP地址" style="width:100%">
                        <el-option label="[真实模式]启用webrtc插件" value="OFF"></el-option>
                        <el-option label="[禁用模式A]禁用webrtc插件" value="BLOCK"></el-option>
                        <el-option label="[替换模式]返回指定的IP地址" value="FAKE"></el-option>
                        <el-option label="[禁用模式B]禁用webrtc插件(全面)" value="BLOCKB"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="11">
                <el-form-item label="公网IP：">
                    <el-switch :disabled="form.webRtc.type !== 'FAKE'" v-model="form.webRtc.wanSet"></el-switch>
                </el-form-item>
            </el-col>
            <el-col :span="11" :offset="2">
                <el-form-item label="内网IP：">
                    <el-switch :disabled="form.webRtc.type !== 'FAKE'" v-model="form.webRtc.lanSet"></el-switch>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="8">
                <el-form-item>
                    <el-input :disabled="form.webRtc.type !== 'FAKE' || form.webRtc.fillOnStart || !form.webRtc.wanSet" v-model="form.webRtc.publicIp" style="width:100%"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="2" :offset="1">
                <el-form-item>
                    <el-checkbox :disabled="form.webRtc.type !== 'FAKE' || !form.webRtc.wanSet" v-model="form.webRtc.fillOnStart" label="">自动检测IP</el-checkbox>
                </el-form-item>
            </el-col>
            <el-col :span="8" :offset="2">
                <el-form-item>
                    <el-input :disabled="form.webRtc.type !== 'FAKE' || !form.webRtc.lanSet" v-model="form.webRtc.localIps[0]" style="width:100%"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="2" :offset="1">
                <el-form-item>
                    <el-button :disabled="form.webRtc.type !== 'FAKE' || !form.webRtc.lanSet" type="primary" @click="randomIp">随机</el-button>
                </el-form-item>
            </el-col>
        </el-row>
        <el-form-item label="代理设置：">
            <el-radio-group v-model="form.resources_id" @change="proxyChange">
                <el-radio-button :label="0">自定义</el-radio-button>
                <el-radio-button :label="selectedId">已购买资源</el-radio-button>
            </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.resources_id === 0">
            <el-select v-model="form.proxyServer.type" style="width:100%">
                <el-option label="NOPROXY(直连模式)" value="NOPROXY"></el-option>
                <el-option label="HTTP" value="HTTP"></el-option>
                <el-option label="SOCKS4" value="SOCKS4"></el-option>
                <el-option label="SOCKS5" value="SOCKS5"></el-option>
                <el-option label="HTTPS" value="HTTPS"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item v-if="form.resources_id !== 0">
            <el-select v-model="selectedId" style="width:100%" placeholder="请选择" clearable @change="resouceChange" @clear="clearSele">
                <template v-if="openDialog">
                    <el-option v-for="(item) in resourcesData.filter(item => item.status === 1)" :key="item.id" :label="item.agent.host + ' - ' + item.area_name" :value="item.id" :disabled="item.status !== 1"></el-option>
                </template>
                <template v-else>
                    <el-option v-for="(item) in processResources" :key="item.id" :label="item.agent.host + ' - ' + item.area_name" :value="item.id"></el-option>
                </template>
            </el-select>
        </el-form-item>
        <el-row>
            <el-col :span="11">
                <el-form-item label="IP地址：" v-if="form.proxyServer.type !== 'NOPROXY' && form.resources_id === 0">
                    <el-input v-model="form.proxyServer.host"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="11" :offset="2">
                <el-form-item label="端口：" v-if="form.proxyServer.type !== 'NOPROXY' && form.resources_id === 0">
                    <el-input v-model="form.proxyServer.port"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="11">
                <el-form-item label="登录用户：" v-if="form.proxyServer.type !== 'NOPROXY' && form.resources_id === 0">
                    <el-input v-model="form.proxyServer.username"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="11" :offset="2">
                <el-form-item label="登录密码：" v-if="form.proxyServer.type !== 'NOPROXY' && form.resources_id === 0">
                    <el-input v-model="form.proxyServer.password"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-form-item label="备注信息：">
            <el-input v-model="form.notes" type="textarea" :rows="5"></el-input>
        </el-form-item>
        
        
        <el-row type="flex" align="middle">
            <el-col :span="11">
                <el-form-item label="分辨率：">
                    <el-select v-model="resolution" :disabled="form.screenAutoDetected" style="width:100%">
                        <el-option v-for="item in browserConstDir.resolutionSeletList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="7" :offset="2">
                <el-form-item label="DevicePixelRatio：">
                    <el-select v-model="form.pixelRatio" :disabled="form.screenAutoDetected" style="width:100%">
                        <el-option v-for="item in browserConstDir.pixelList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="3" :offset="1">
                    <el-checkbox v-model="form.screenAutoDetected" label="">跟随电脑</el-checkbox>
            </el-col>
        </el-row>
        
        <el-row type="flex" align="middle">
            <el-col :span="11">
                <el-form-item label="语言：">
                    <el-select v-model="form.langHdr" :disabled="form.langBasedOnIp" style="width:100%">
                        <el-option v-for="item in browserConstDir.selectLanguageList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="7" :offset="2">
                <el-form-item label="Accept-Language：">
                    <el-select v-model="form.acceptLanguage" :disabled="form.langBasedOnIp" style="width:100%">
                        <el-option v-for="item in browserConstDir.acceptLangList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="3" :offset="1">
                    <el-checkbox v-model="form.langBasedOnIp" label="">基于IP设置语言</el-checkbox>
            </el-col>
        </el-row>
        <el-row type="flex" align="middle">
            <el-col :span="11">
                <el-form-item label="HardwareConcurrency：">
                    <el-select v-model="form.hardwareConcurrency" :disabled="form.deviceMemoryCPUAuto" style="width:100%">
                        <el-option v-for="item in browserConstDir.HardwareConcurrencyNumList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="7" :offset="2">
                <el-form-item label="设备内存：">
                    <el-select v-model="form.deviceMemory" :disabled="form.deviceMemoryCPUAuto" style="width:100%">
                        <el-option v-for="item in browserConstDir.deviceMemoryList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="3" :offset="1">
                    <el-checkbox v-model="form.deviceMemoryCPUAuto" label="">跟随电脑</el-checkbox>
            </el-col>
        </el-row>
       
        <el-row type="flex" align="middle">
            <el-col :span="11">
                <el-form-item label="移动仿真：">
                    <el-switch v-model="form.mobileEmulation"></el-switch>
                </el-form-item>
            </el-col>
            <el-col :span="11" :offset="2">
                <el-form-item>
                    <el-select v-model="form.deviceType">
                        <el-option label="Desktop" value="0"></el-option>
                        <el-option label="Mobile" value="1"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
  </div>
</template>

<script>
import browserConstDir from '@/utils/browserConstDir'
import {generateIpBySort} from '@/utils/randomLocalIP'
import {mapGetters} from 'vuex'
export default {
    props: ['form','groupId','userId','groupData','accountData','browserVerList','uaList','resourcesData','openDialog'],
    data(){
        return{
            resolution: '',
            browserConstDir: browserConstDir,
            group: this.groupId,
            user: this.userId,
            uaSelect: [],
            selectedId: null,
            selectedResource: {},
            processResources: []
        }
    },
    computed:{
        ...mapGetters(['roles'])
    },
    watch:{
        form:{
            deep: true,
            handler(val){
                this.$emit('update:form',val)
                this.resolution = `${this.form.screenWidth}x${this.form.screenHeight}`
            }
        },
        group(val){
            this.$emit('update:groupId',val)
        },
        user(val){
            this.$emit('update:userId',val)
        },
        groupId(val){
            this.group = val
        },
        userId(val){
            this.user = val
        },
        resolution(val){
            let arr = val.split('x')
            this.form.screenWidth = arr[0]
            this.form.screenHeight = arr[1]
        },
        'form.resources_id'(val){
            if(val !== 0){
                this.selectedId = val
            }
        }
    },
    mounted(){
        if(this.openDialog){
            this.genSelect()
            this.randomIp()

        }else{
            if(this.form.resources_id !== 0){
                let findObj = this.resourcesData.find(i => i.id === this.form.resources_id)
                console.log(findObj)
                this.selectedId = findObj.id
                this.processResources.push(findObj)
                this.resourcesData.forEach(i => {
                    if(i.status === 1){
                        this.processResources.push(i)
                    }
                })
            }
            if(!this.form.resources_id){
                this.resourcesData.forEach(i => {
                    if(i.status === 1){
                        this.processResources.push(i)
                    }
                })
            }
        }
    },
    methods:{
        proxyChange(proxyType){
            if(proxyType === 0){
                this.form.proxyServer = {
                    type: "NOPROXY",
                    host: "",
                    port: "",
                    username: "",
                    password: ""
                }
            }else{
                this.form.proxyServer = this.resourcesData.find(i => i.id === this.selectedId).agent
                // this.selectedId = null
                // this.form.resources_id = null
                // this.form.proxyServer = {
                //     type: "",
                //     host: "",
                //     port: "",
                //     username: "",
                //     password: ""
                // }
            }
        },
        resouceChange(selectedId){
            if(selectedId){
                this.form.resources_id = selectedId
                this.form.proxyServer = this.resourcesData.find(i => i.id === selectedId).agent
            }else{
                this.form.proxyServer = {
                    type: "",
                    host: "",
                    port: "",
                    username: "",
                    password: ""
                }
            }
            
        },
        clearSele(){
            this.selectedId = null
            this.form.resources_id = null
        },
        genSelect(){
            let arr = []
            this.form.os.browserVer.forEach(key => {
                arr.push(this.uaList[key])
            })
            this.uaSelect = arr.flat()
            this.randomUA()
        },
        randomUA(){
            let index = Math.floor(Math.random() * this.uaSelect.length)
            this.$set(this.form,'userAgent',this.uaSelect[index])
        },
        randomIp(){
            const ipList = [ "172.16.0.0-172.31.255.255", "10.0.0.0-10.255.255.255","192.168.0.0-192.168.255.255"]
            let ipRangeIndex = Math.floor(Math.random() * ipList.length)
            const ipListRet = generateIpBySort([ipList[ipRangeIndex]], 9999)
            let index = Math.floor(Math.random() * ipListRet.length)
            this.form.webRtc.localIps[0] = ipListRet[index]
            this.$set(this.form.webRtc,'localIps',[ipListRet[index]])
        },
    }
}
</script>

<style lang="scss" scoped>

</style>