<template>
  <div>
    <el-form v-model="form">
        <el-form-item>
            <div class="switch-wrap-label">远程调试功能</div>
            <div class="switch-wrap">
                <el-form-item>
                    <el-switch v-model="form.remoteDebug.bindAllDebug" active-text="端口绑定0.0.0.0"></el-switch>
                </el-form-item>
                <el-row>
                    <el-col :span="11">
                        <el-form-item label="远程调试端口">
                            <el-input v-model="form.remoteDebug.debuggingPort"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" :offset="2">
                        <el-form-item label="调试日志级别">
                            <el-select v-model="form.remoteDebug.logLevels" style="width:100%">
                                <el-option label="VERBOSE" value="1"></el-option>
                                <el-option label="DISABLE" value="99"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
        </el-form-item>
        <el-form-item>
            <div class="switch-wrap-label">其他防护</div>
            <div class="switch-wrap">
                <el-form-item>
                    <el-switch v-model="form.otherProtection.setPortScan" active-text="开启端口扫描保护"></el-switch>
                </el-form-item>
                <el-form-item>
                    <div style="color:#ed6b08">这将阻止网站探测您打开了哪些本地网络的端口，默认情况下该功能将阻止所有端口被扫描</div>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="form.otherProtection.localPortsExclude" placeholder="允许被扫描的端口"></el-input>
                </el-form-item>
                <el-form-item>
                    <div>您需要用逗号分隔被列入白名单的端口</div>
                </el-form-item>
            </div>
        </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
    props:{
        form:{
            type: Object,
            default: () => {}
        }
    },
    watch:{
        form:{
            deep: true,
            handler(val){
                this.$emit('update:form',val)
            }
        }
    },
    data(){
        return{

        }
    },
    methods:{
        
    }
}
</script>

<style lang="scss" scoped>
.switch-wrap-label{
    font-size: 16px;
}
.switch-wrap{
    border-radius: 10px;
    border: 1px solid #ff6e00;
    padding: 20px;
}
</style>