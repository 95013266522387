<template>
  <div>
    <el-form v-model="form">
        <el-form-item>
            <div class="switch-wrap-label">媒体设备</div>
            <div class="switch-wrap">
                <el-row>
                    <el-col :span="11">
                        <el-form-item>
                            <el-switch v-model="form.mediaDevices.setMediaDevices" active-text="自定义媒体设备数量"></el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" :offset="2">
                        <el-form-item>
                            <el-switch :disabled="!form.mediaDevices.setMediaDevices" v-model="form.mediaDevices.use_name" active-text="指定设备名称"></el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item style="padding-bottom:10px">
                    <el-row type="flex" align="middle">
                        <el-col :span="5">
                            <el-input-number :disabled="!form.mediaDevices.setMediaDevices" v-model="form.mediaDevices.videoInputs.count" controls-position="right"  :min="0" :max="1"></el-input-number>
                        </el-col>
                        <el-col :span="5">
                            <span>视频输入</span>
                        </el-col>
                        <el-col :span="11" :offset="3">
                            <el-button :disabled="!form.mediaDevices.setMediaDevices || !form.mediaDevices.use_name" type="primary" @click="editDevice('编辑视频输入设备信息','videoInputs')">编辑设备名称</el-button>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item style="padding-bottom:10px">
                    <el-row type="flex" align="middle">
                        <el-col :span="5">
                            <el-input-number :disabled="!form.mediaDevices.setMediaDevices" v-model="form.mediaDevices.audioInputs.count" controls-position="right"  :min="1" :max="4"></el-input-number>
                        </el-col>
                        <el-col :span="5">
                            <span>音频输入</span>
                        </el-col>
                        <el-col :span="11" :offset="3">
                            <el-button :disabled="!form.mediaDevices.setMediaDevices || !form.mediaDevices.use_name" type="primary" @click="editDevice('编辑音频输入设备信息','audioInputs')">编辑设备名称</el-button>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item>
                    <el-row type="flex" align="middle">
                        <el-col :span="5">
                            <el-input-number :disabled="!form.mediaDevices.setMediaDevices" v-model="form.mediaDevices.audioOutputs.count" controls-position="right"  :min="1" :max="4"></el-input-number>
                        </el-col>
                        <el-col :span="5">
                            <span>音频输出</span>
                        </el-col>
                        <el-col :span="11" :offset="3">
                            <el-button :disabled="!form.mediaDevices.setMediaDevices || !form.mediaDevices.use_name" type="primary" @click="editDevice('编辑音频输出设备信息','audioOutputs')">编辑设备名称</el-button>
                        </el-col>
                    </el-row>
                </el-form-item>
            </div>
        </el-form-item>
        <el-form-item>
            <div class="switch-wrap-label">防泄漏设备</div>
            <div class="switch-wrap">
                <el-row type="flex" align="middle">
                    <el-col :span="11">
                        <el-form-item>
                            <el-switch v-model="form.leakProof.computerName.isUse" active-text="电脑名称"></el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" :offset="2">
                        <el-form-item>
                            <el-switch v-model="form.leakProof.macAddress.isUse" active-text="Mac地址"></el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="5">
                        <el-form-item>
                            <el-input :disabled="!form.leakProof.computerName.isUse" v-model="form.leakProof.computerName.value"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5" :offset="1">
                        <el-form-item>
                            <el-button :disabled="!form.leakProof.computerName.isUse" type="primary" @click="randomDeviceName">随机</el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5" :offset="2">
                        <el-form-item>
                            <el-input :disabled="!form.leakProof.macAddress.isUse" v-model="form.leakProof.macAddress.value"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5" :offset="1">
                        <el-form-item>
                            <el-button :disabled="!form.leakProof.macAddress.isUse" type="primary" @click="randomMacAddress">随机</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
        </el-form-item>
    </el-form>
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" width="30%" :append-to-body="true">
        <el-form label-width="80px" class="form-device-wrap">
            <div v-for="item in form.mediaDevices[deviceKey].count" :key="item">
                <div class="title-wrap">
                    <div class="device-title">设备{{item}}</div>
                    <div class="device-op" @click="delDevice">
                        <i class="el-icon-delete"></i>删除设备{{item}}
                    </div>
                </div>
                <div class="main-wrap">
                    <el-form-item label="label">
                        <el-input v-model="form.mediaDevices[deviceKey][`device${item}`].label"></el-input>
                    </el-form-item>
                    <el-form-item label="deviceId">
                        <el-input v-model="form.mediaDevices[deviceKey][`device${item}`].deviceId"></el-input>
                    </el-form-item>
                    <el-form-item label="groupId">
                        <el-input v-model="form.mediaDevices[deviceKey][`device${item}`].groupId"></el-input>
                    </el-form-item>
                </div>
            </div>
            <div class="add-device main-wrap" @click="addDevice">
                <i class="el-icon-plus" style="margin-right: 8px;"></i>增加设备
            </div>
        </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {getRandomMac,getRandomDeviceName} from '@/utils/common'
export default {
    props: ['form','openDialog'],
    watch:{
        form:{
            deep: true,
            handler(val){
                this.$emit('update:form',val)
            }
        },
        'form.mediaDevices.videoInputs.count'(val){
            this.deviceCount(val,'videoInputs')
        },
        'form.mediaDevices.audioInputs.count'(val){
            this.deviceCount(val,'audioInputs')
        },
        'form.mediaDevices.audioOutputs.count'(val){
            this.deviceCount(val,'audioOutputs')
        },
    },
    data(){
        return{
            dialogTitle: '',
            showDialog: false,
            deviceKey: 'videoInputs',
        }
    },
    mounted(){
        if(this.openDialog){
            this.randomDeviceName()
            this.randomMacAddress()
            this.randomCount(1,'videoInputs')
            this.randomCount(4,'audioInputs')
            this.randomCount(4,'audioOutputs')
        }
    },
    methods:{
        randomCount(val,key){
            let index = Math.floor(Math.random() * val)
            this.form.mediaDevices[key].count = index
        },
        randomDeviceName(){
            this.form.leakProof.computerName.value = getRandomDeviceName()
        },
        randomMacAddress(){
            this.form.leakProof.macAddress.value = getRandomMac()
        },
        deviceCount(val,key){
            this.form.mediaDevices[key] = {count:val}
            for(let i=1;i<=val;i++){
                let obj = {label:'',deviceId:'',groupId:''}
                this.$set(this.form.mediaDevices[key],`device${i}`,obj)
            }
        },
        editDevice(title,deviceKey){
            this.dialogTitle = title
            this.showDialog = true
            this.deviceKey = deviceKey
        },
        addDevice(){
            this.form.mediaDevices[this.deviceKey].count++
        },
        delDevice(){
            this.form.mediaDevices[this.deviceKey].count--
        },
    }
}
</script>

<style lang="scss" scoped>
.title-wrap{
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin: 20px 0;
    color: #ed6b08;
    .device-op{
        cursor: pointer;
        i{
            margin-right: 8px;
        }
    }
}
.main-wrap{
    border: 1px solid #ed6b08;
    background-color: #fef6f0;
    padding: 20px;
    border-radius: 10px;
}
.add-device{
    color: #ed6b08;
    font-size: 18px;
    cursor: pointer;
    margin-top: 20px;
}
.form-device-wrap{
    height:50vh;
    overflow:auto;
    &::-webkit-scrollbar{
        width: unset;
    }
}
::v-deep{
    .el-input-number{
        .el-input__inner{
            border: 1px solid #ed6b08 !important;
        }
        .el-input-number__increase,.el-input-number__decrease{
            background: #fef6f0;
            color: #ed6b08;
            border-color: #ed6b08;
        }
    }
}
.switch-wrap-label{
    font-size: 16px;
}
.switch-wrap{
    border-radius: 10px;
    border: 1px solid #ff6e00;
    padding: 20px;
}
</style>