<template>
  <div>
    <el-form v-model="form">
        <el-form-item>
            <el-switch v-model="form.cmdcfg.openCommandLine" active-text="打开浏览器启动前执行命令行"></el-switch>
        </el-form-item>
        <el-row type="flex" align="middle">
            <el-col :span="11">
                <el-form-item label="命令行">
                    <el-input :disabled="!form.cmdcfg.openCommandLine" v-model="form.cmdcfg.commandLine"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="11" :offset="2">
                    <el-switch class="item-no-label" :disabled="!form.cmdcfg.openCommandLine" v-model="form.cmdcfg.fillOnStart" active-text="自动检测IP"></el-switch>
            </el-col>
        </el-row>
        
        
    </el-form>
  </div>
</template>

<script>
export default {
    props:{
        form:{
            type: Object,
            default: () => {}
        }
    },
    watch:{
        form:{
            deep: true,
            handler(val){
                this.$emit('update:form',val)
            }
        }
    },
    data(){
        return{

        }
    },
    methods:{

    }
}
</script>

<style>

</style>